<template>
  <div class="content_body WeiXinAuthorizationError back_f8" >
    <div class="dis_flex flex_dir_column backfff border radius10 content" >
      <i class="el-icon-warning" style="font-size:100px;color:#E6E6E6;margin-top:20px"></i>
      <p class="martp_20 color_999 font_16" >绑定失败请重试</p>
      <el-button class="martp_10" style="width:100px"  plain @click="closeClick">关闭本页</el-button>
    </div>
  </div>
</template>

<script>
export default {
 name: 'WeiXinAuthorizationError',
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
    /** 关闭本页   */
    closeClick(){
      window.opener=null;
      window.close();
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
}
</script>

<style lang="scss">
.WeiXinAuthorizationError{

  display: flex;
  justify-content: center;
  .content{
    height:250px;
    width:500px;
    margin-top:100px;
    text-align: center; 
    align-items: center;
  }
}


</style>
