import Vue from 'vue';

Vue.filter('NumFormat', function (value) {
    if (!value) return '0.00';
    /*原来用的是Number(value).toFixed(0)，这样取整时有问题，例如0.51取整之后为1，感谢Nils指正*/
    /*后来改成了 Number(value)|0,但是输入超过十一位就为负数了，具体见评论 */
    var intPart = Number(value) - Number(value) % 1; //获取整数部分（这里是windy93的方法）

    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
    if (value < 0 && intPart == 0) {
        intPartFormat = "-0";
    }
    var floatPart = ".00"; //预定义小数部分
    var value2Array = value.toString().split(".");

    //=2表示数据有小数位
    if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分

        if (floatPart.length == 1) { //补0,实际上用不着
            return intPartFormat + "." + floatPart + '0';
        } else {
            return intPartFormat + "." + floatPart;
        }

    } else {
        return intPartFormat + floatPart;
    }

});

Vue.filter('Fixed_2', function (value) {
    if (!value) return 0.00
    value = Number(value).toFixed(2);
    if (value == parseInt(value)) {
        value = parseInt(value);
    }
    return value;
});
