<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  mounted() {
    window._AMapSecurityConfig = {
      securityJsCode: "a1dc1b4e95a9a56b52251b00446b014f",
    };
  },
};
</script>

<style lang="scss">
// @import "assets/css/color.scss";
@import "./assets/css/main.css";
</style>
