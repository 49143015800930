import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login/login'
import ResetPassword from '@/views/login/resetPassword'
import AuthorizationError from '@/views/Marketing/App/WeiXinAuthorizationError'
import AuthorizationErrorSucceed from '@/views/Marketing/App/WeiXinAuthorizationSucceed'

import API from '@/api/account'

Vue.use(Router)
let route = new Router({
  routes: [
    {
      path: '/login',
      name: '登录',
      component: Login,
    },
    {
      path: '/resetPassword',
      name: '修改密码',
      component: ResetPassword,
    },
    {
      path: '/Marketing/WeiXinAuthorizationError',
      name: '',
      component: AuthorizationError,
    },
    {
      path: '/Marketing/WeiXinAuthorizationSucceed',
      name: '',
      component: AuthorizationErrorSucceed,
    },
  ],
  mode: "history",
})

route.$addRoutes = (params) => {
  route.matcher = new Router({
    routes: [
      {
        path: '/login',
        name: '登录',
        component: Login,
      },
      {
        path: '/resetPassword',
        name: '修改密码',
        component: ResetPassword,
      },
      {
        path: '/Marketing/WeiXinAuthorizationError',
        name: '',
        component: AuthorizationError,
      },
      {
        path: '/Marketing/WeiXinAuthorizationSucceed',
        name: '',
        component: AuthorizationErrorSucceed,
      },
    ], mode: 'history'
  }).matcher;
  route.addRoutes(params)
}
if (localStorage.getItem("access-user") != undefined) {
  API.getPCPermissionRouter()
    .then(res => {
      console.log(res)
      if (res.StateCode == 200) {
        var routerchildren = [];
        res.Data.forEach(function (item) {
          var routerChild = {};
          routerChild.path = item.RouterPath;
          routerChild.component = () => import(`@/${item.RouterComponentPath}`);
          routerChild.name = item.RouterName;
          var routerMeta = {};
          routerMeta.title = item.RouterMeta.Title;
          routerMeta.ExternalLinks = item.RouterMeta.ExternalLinks;
          routerMeta.IsVerifyStore = item.RouterMeta.IsVerifyStore;
          routerMeta.IsVerifyWarehouse = item.RouterMeta.IsVerifyWarehouse;
          routerMeta.Permission = item.RouterMeta.Permission;
          routerChild.meta = routerMeta;
          routerchildren.push(routerChild);
        });
        var routers = [];
        var router = {};
        router.path = "/";
        router.name = 'UserRouter';
        router.component = () => import("@/components/common/Master");
        router.children = routerchildren;
        routers.push(router);
        var routerNotFound = {};
        routerNotFound.path = "*";
        routerNotFound.redirect = '/';
        routers.push(routerNotFound);
        route.$addRoutes(routers);
        console.log(routers);
      }
    })
} else {
  var routers = [];
  var router = {};
  router.path = "*";
  router.redirect = '/login';
  routers.push(router);
  route.$addRoutes(routers);
}


export default route;
