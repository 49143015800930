/**
 * Created by jerry on 2020/02/14.
 * 登录api
 */
import * as API from '.'

export default {
    // 登录
    getlogin: params => {
        return API.POST('api/login', params)
    },
    // 路由权限
    getPCPermissionRouter: params => {
        return API.POST('api/account/getPCPermissionRouter', params)
    },
    // 权限
    getSidebar: params => {
        return API.POST('api/account/getPCMenuPermission', params)
    },
    // 获取用户权限范围
    getEntity: params => {
        return API.POST('api/account/entity', params)
    },
    // 	切换门店
    getSwitchEntity: params => {
        return API.POST('api/account/switchEntity', params)
    },
    // 修改密码
    setPassword: params => {
        return API.POST('api/account/changePassword', params)
    },
}
