<template>
  <div class="content_body WeiXinAuthorizationSucceed back_f8" >
    <div class="dis_flex flex_dir_column backfff border radius10 content" >
      <i class="el-icon-success" style="font-size:100px;color:#67B836;margin-top:20px"></i>
      <p class="martp_20" style="color:#67B836;font-size:20px">恭喜，授权成功</p>
      <p class="font_12 color_666 martp_20">本页将在{{timerNumber}}秒后自动关闭</p>
    </div>
  </div>
</template>

<script>
export default {
 name: 'WeiXinAuthorizationSucceed',
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      timerNumber:5,
      timer:""
    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {  
    /**    */
    colseWindow(){
      window.opener=null;
      window.close();
    },

    getParams() {
      // 取到路由带过来的参数
      const routerParams = this.$route.query;
      console.log("接受的pdf的值：", routerParams);
     }
  },
  created() {
    this.getParams();
  },

  /**  实例被挂载后调用  */
  mounted() {
    let that = this;
     that.timer = setInterval(()=>{
        that.timerNumber--
        if(that.timerNumber===0){
          clearInterval(that.timer)
          that.colseWindow();
        }
      },1000)
  },
  /**  实例销毁之前调用  */
  beforeDestroy() {
    let that = this
    clearInterval(that.timer);
  },
}
</script>

<style lang="scss">
.WeiXinAuthorizationSucceed{
  display: flex;
  justify-content: center;
  .content{
    height:250px;
    width:500px;
    margin-top:100px;
    text-align: center; 
  }
}

</style>
