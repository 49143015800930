<template>
  <div class="height_fill_available">
    <!--<div></div>-->
    <el-row class="height_fill_available">
      <el-col :span="12" class="height_fill_available dis_flex flex_y_center flex_x_end">
        <div class="map_img">
          <img src="@/assets/img/map.png" alt />
        </div>
      </el-col>
      <el-col :span="12" class="height_fill_available dis_flex flex_y_center flex_x_center">
        <div class="login_information">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="0px" class="demo-ruleForm login-container">
            <div class="login_logo">
              <img class="login_img" src="@/assets/img/logo@2x.png" alt />
            </div>
            <!-- <el-form-item prop="EnterpriseCode">
              <el-input v-model="ruleForm.EnterpriseCode" placeholder="商户ID"></el-input>
            </el-form-item> -->
            <el-form-item prop="Username">
              <el-input v-model="ruleForm.Username" placeholder="账号" @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <el-form-item prop="Password">
              <el-input type="password" v-model="ruleForm.Password" placeholder="密码" @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <!--            <el-form-item>-->
            <!--              <el-checkbox v-model="savePasswordState" @change="selectRememberPassword">记住密码</el-checkbox>-->
            <!--            </el-form-item>-->
            <el-form-item class="pad_15_0">
              <el-button type="primary" style="width:100%;" @click.native.prevent="handleLogin" :loading="loading">登录</el-button>
            </el-form-item>

            <!-- <div class="forget_password text_right pad_15" @click="resetPassword">忘记密码?</div> -->
          </el-form>
          
          <div class="dis_flex flex_dir_row flex_x_between" style="margin-top: 50px">
            <div style="margin-left: 25px" class="dis_flex flex_dir_row flex_y_center flex_x_between">
              <img src="@/assets/img/beianIcon.png" alt="" />
              <el-link class="font_13 color_999 marlt_5" type="info" :underline="false" href="https://beian.miit.gov.cn" target="_blank"
                >苏ICP备2023020931号-1</el-link
              >
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import API from "@/api/account";
import axios from "axios";
import md5 from "js-md5";

export default {
  data() {
    return {
      loading: false,
      // savePasswordState: false,                          // 记住密码
      ruleForm: {
        EnterpriseCode: "xms",
        Username: "",
        Password: "",
      },
      rules: {
        // EnterpriseCode: [
        //   { required: true, message: "请输入商户号ID", trigger: "blur" },
        // ],
        Username: [
          { required: true, message: "请输入账号或手机号", trigger: "blur" },
        ],
        Password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 登录
    handleLogin() {
      var that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let para = Object.assign({}, that.ruleForm);
          para.Password = md5(para.Password);
          API.getlogin(para)
            .then(function (res) {
              if (res.StateCode == 200) {
                localStorage.setItem("access-user", JSON.stringify(res.Data));
                that.routerData();
                // if (that.savePasswordState) {
                //   localStorage.setItem("savePasswordState", 1);
                //   localStorage.setItem("password", md5(that.ruleForm.Password));
                localStorage.setItem(
                  "EnterpriseCode",
                  that.ruleForm.EnterpriseCode
                );
                //   localStorage.setItem("Username", that.ruleForm.Username);
                // }else{
                //   if(localStorage.getItem('password') != undefined || localStorage.getItem('password') != null){
                //     localStorage.setItem("savePasswordState", 0);
                //     localStorage.removeItem("password");
                //     localStorage.removeItem("EnterpriseCode");
                //     localStorage.removeItem("Username");
                //   }
                // }
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.loading = false;
            });
        }
      });
    },
    routerData: function () {
      var that = this;
      API.getPCPermissionRouter().then((res) => {
        if (res.StateCode == 200) {
          var routerchildren = [];
          res.Data.forEach(function (item) {
            var routerChild = {};
            routerChild.path = item.RouterPath;
            routerChild.component = () =>
              import(`@/${item.RouterComponentPath}`);
            routerChild.name = item.RouterName;
            var routerMeta = {};
            routerMeta.title = item.RouterMeta.Title;
            routerMeta.ExternalLinks = item.RouterMeta.ExternalLinks;
            routerMeta.IsVerifyStore = item.RouterMeta.IsVerifyStore;
            routerMeta.IsVerifyWarehouse = item.RouterMeta.IsVerifyWarehouse;
            routerMeta.Permission = item.RouterMeta.Permission;
            routerChild.meta = routerMeta;
            routerchildren.push(routerChild);
          });
          var routers = [];
          var router = {};
          router.path = "/";
          router.name = "UserRouter";
          router.component = () => import("@/components/common/Master");
          router.children = routerchildren;
          routers.push(router);
          var routerNotFound = {};
          routerNotFound.path = "*";
          routerNotFound.redirect = "/";
          routers.push(routerNotFound);
          that.$router.$addRoutes(routers);
          that.$router.push("/");
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 重置密码
    resetPassword: function () {
      //   var that = this;
      // that.$router.push("resetPassword")
    },
    // 记住密码
    selectRememberPassword() {},
  },
  // beforeRouteEnter(to,from,next){
  //   next(vm => {
  //     if (Number(localStorage.getItem('savePasswordState')) == 1) {
  //       vm.savePasswordState = true
  //     }else{
  //       vm.savePasswordState = false
  //     }
  //   })
  // },
  // mounted() {
  //   var that = this;
  //   if(Number(localStorage.getItem('savePasswordState')) == 1){
  //     that.ruleForm.Password = localStorage.getItem('password');
  //     that.ruleForm.EnterpriseCode = localStorage.getItem('EnterpriseCode');
  //     that.ruleForm.Username = localStorage.getItem('Username');
  //   }
  // }
};
</script>

<style scoped lang="scss">
.logo {
  padding: 15px;
}

.map_img {
  width: 500px;
  height: 500px;
  img {
    width: 100%;
    height: 100%;
  }
}

.login_information {
  width: 400px;
  border: 1px solid #eeeeee;
  padding: 25px;
  border-radius: 5px;
  .login_logo {
    text-align: center;
    margin-bottom: 25px;
  }

  .el-input__inner {
    border: none !important;
    border-bottom: 1px solid #eeeeee !important;
    padding: 15px;
  }
  .forget_password {
    color: #666;
  }
}
</style>