import Vue from 'vue';

Vue.directive('enterNumber', {
    inserted: function (el) {
        el.addEventListener("keyup",function(e){
            e = e || window.event;
            let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
            let re = /\d/;
            if(!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey){
                if(e.preventDefault){
                    e.preventDefault();
                }else{
                    e.returnValue = false;
                }
            }
        });
    }
});
// Vue.directive('enterNumber2', {
//     inserted: function (el) {
//         el.addEventListener("keyup",function(e){
//             e = e || window.event;
//               // 通过正则过滤小数点后两位zz
//             e.target.value = (e.target.value.match(/^\d*(.?\d{0,2})/g)[0]) || null
//         });
//     }
// });
Vue.directive('enterNumber2', {
  bind: (el, binding, vnode) => {
    let input = null;
    if (vnode.elm.children.length > 1) {
      for (let index = 0; index < vnode.elm.children.length; index++) {
        const element = vnode.elm.children[index];
        if (element.tagName === "INPUT") {
          input = element
        }
      }
    }
    else{
      input = vnode.elm.children[0];
    }
    input.addEventListener("compositionstart", () => {
      vnode.inputLocking = true;
    });
    input.addEventListener("compositionend", () => {
      vnode.inputLocking = false;
      input.dispatchEvent(new Event("input"));
    });
    input.addEventListener("input", () => {
      if (vnode.inputLocking) {
        return;
      }
      let oldValue = input.value;
      let newValue = input.value;
      switch (binding.value) {
        case 0 /** 整数  */:
          newValue = newValue.replace(/[^\d]/g, "");
          break;
        case 1 /**  保留一位小数  */:
          {
            newValue = newValue
              .replace(/[^\d^.]/g, "") /**  把不是数字，不是小数点的过滤掉  */
              .replace(".", "$#$")
              .replace(/\./g, "") /**  验证第一位是不是小数点  */
              .replace("$#$", ".")
              .replace(/^(-)*(\d+)\.(\d).*$/, "$1$2.$3");
          }
          break;
        default:
          {
            /**  保留两位小数  */
            newValue = newValue.replace(/[^\d.]/g, "");
            newValue = newValue.replace(/^\./g, "");
            newValue = newValue
              .replace(".", "$#$")
              .replace(/\./g, "")
              .replace("$#$", ".");
            newValue = newValue.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
          }
          break;
      }
      if (newValue) {
        let arr = newValue.split(".");
        // 去掉开头多余的0
        newValue = Number(arr[0]) + (arr[1] === undefined ? "" : "." + arr[1]);
      }
      // 判断是否需要更新，避免进入死循环
      if (newValue !== oldValue) {
        input.value = newValue;
        input.dispatchEvent(new Event("input")); // 通知v-model更新
      }
    });
    // input 事件无法处理小数点后面全是零的情况 因为无法确定用户输入的0是否真的应该清除，如3.02。放在blur中去处理
    input.addEventListener("blur", () => {
      let oldValue = input.value;
      let newValue = input.value;
      if (newValue) {
        newValue = Number(newValue).toString();
      }
      // 判断是否需要更新，避免进入死循环
      if (newValue !== oldValue) {
        input.value = newValue;
        input.dispatchEvent(new Event("input")); // 通知v-model更新
      }
    });
  },
    // inserted: function (el) {
    //     el.addEventListener("keyup",function(e){
    //       console.log(e);
    //         e = e || window.event;
    //         //   通过正则过滤小数点后两位
    //         if (e.keyCode !== 110) {
    //           e.target.value =(e.target.value.match(/^\d*(.?\d{0,2})/g)[0]) || null  
    //         }
    //     });
    // }
});

Vue.directive('enterNumber3', {
  inserted: function (el) {
      el.addEventListener("keyup",function(e){
          e = e || window.event;
          e.target.value =Math.abs(e.target.value)
      });
      el.addEventListener("focusout",e=>{
          if(e.target.value.indexOf('.')!=-1){
              e.target.value=Number(e.target.value).toFixed(2)
          }
          
      })
  }
});
Vue.directive('enterInt', {
    inserted: function (el) {
        el.addEventListener("keyup",function(e){
            e = e || window.event;
              // 过滤小数点
            e.target.value = (e.target.value.match(/^\d*/g)[0]) || null
        });
    }
});

 Vue.directive('preventClick', {
    inserted: function (el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 1000)
            }
        })
    }
});

/**  做下拉加载更多使用  */
Vue.directive('loadmore', {
  bind (el, binding) {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap ')
      SELECTWRAP_DOM.addEventListener('scroll', function () {
          const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (CONDITION) {
              binding.value()
          }
      })
  }
})

Vue.directive('scrollbar_loadmore', {
  bind (el, binding) {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector('.el-scrollbar .el-scrollbar__wrap')
      SELECTWRAP_DOM.addEventListener('scroll', function () {
          const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (CONDITION) {
              binding.value()
          }
      })
  }
})

// el-input 正整数 positiveNumber
Vue.directive('positiveNumber', {
  inserted: function(el) {

  let inputEls = el.children
  let inputEl = inputEls[0];
    if (inputEl.nodeName == 'INPUT') {
      inputEl.addEventListener("input", function() {
        //进行验证
        checkedfun(inputEl);
      });
    }

    function checkedfun(input_el) {
      if(!input_el.value) return "";
      let reg2 = /^\d+(\.)?/;
      if (!reg2.test(input_el.value)) {
        input_el.value = input_el.value.replace(/[^\d]/g, "");//匹配数字
        input_el.dispatchEvent(new Event("input"));//调用input事件使vue 绑定更新
      }
      else{
        let reg = /^\d+(\.\d+)?$/;
        if (!reg.test(input_el.value)) {
          input_el.value = input_el.value.replace(/[^\d.]/g, "");//匹配数字 和 .
          // input_el.value = input_el.value.replace(/\.{2,}/g,"."); // 保证只有出现一个.而没有多个. 
          // input_el.value = input_el.value.replace(".","$#$").replace(/\./g,"").replace("$#$",".");//保证.只出现一次，而不能出现两次以上 
          input_el.dispatchEvent(new Event("input"));//调用input事件使vue 绑定更新
        }
      }

    }
  }
  
});

export default{
  
}
