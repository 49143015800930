<template>
    <div class="height_fill_available">
        <el-row class="height_fill_available">
            <el-col :span="12" class="height_fill_available dis_flex flex_y_center flex_x_end">
                <div class="map_img">
                    <img src="@/assets/img/map.png" alt/>
                </div>
            </el-col>
            <el-col :span="12" class="height_fill_available dis_flex flex_y_center flex_x_center">
                <div class="login_information">
                    <el-form
                            ref="ruleForm"
                            :model="ruleForm"
                            :rules="rules"
                            label-position="left"
                            label-width="0px"
                            class="demo-ruleForm login-container"
                    >
                        <div class="login_logo">
                            <img class="login_img" src="@/assets/img/logo@2x.png" alt/>
                        </div>
                        <el-form-item prop="Username">
                            <el-input v-model="ruleForm.OldPassword" placeholder="旧密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="Password">
                            <el-input type="password" v-model="ruleForm.NewPassword" placeholder="新密码"></el-input>
                        </el-form-item>
                        <el-form-item class="pad_15_0">
                            <el-button
                                    type="primary"
                                    style="width:100%;"
                                    @click.native.prevent="handleLogin"
                                    :loading="loading"
                            >确认
                            </el-button>
                        </el-form-item>

                    </el-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import API from "@/api/account";
    import axios from "axios";
    import md5 from "js-md5";

    export default {
        data() {
            return {
                loading: false,
                ruleForm: {
                    OldPassword: "",
                    NewPassword: ""
                },
                rules: {
                    OldPassword: [{required: true, message: "请输入旧密码", trigger: "blur"}],
                    NewPassword: [{required: true, message: "请输入新密码", trigger: "blur"}]
                }
            };
        },
        methods: {
            // 登录
            handleLogin() {
                var that = this;
                that.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.loading = true;
                        let para = Object.assign({}, that.ruleForm);
                        para.OldPassword = md5(para.OldPassword);
                        para.NewPassword = md5(para.NewPassword);
                        API.setPassword(para)
                            .then(function (res) {
                                if (res.StateCode == 200) {
                                    that.$message.success({
                                        message: "密码修改成功",
                                        duration: 2000
                                    });
                                    that.$router.go(-1);
                                } else {
                                    that.$message.error({
                                        message: res.Message,
                                        duration: 2000
                                    });
                                }
                            })
                            .finally(function () {
                                that.loading = false;
                            });
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">

    .logo {
        padding: 15px;
    }

    .map_img {
        width: 500px;
        height: 500px;
    img {
        width: 100%;
        height: 100%;
    }
    }

    .login_information {
        width: 400px;
        border: 1px solid #eeeeee;
        padding: 25px;
        border-radius: 5px;
    .login_logo {
        text-align: center;
        margin-bottom: 25px;
    }

    .el-input__inner {
        border: none !important;
        border-bottom: 1px solid #eeeeee !important;
        padding: 15px;
    }
    .forget_password {
        color: #666;
    }
    }
</style>